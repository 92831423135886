import {
  Advance_Styling_with_Responsive_Design_CERTIFICATE_PNG,
  Capstone_CERTIFICATE_PNG,
  Capstone_Python_Certificate_PNG,
  Interactivity_with_javaScript_CERTIFICATE_PNG,
  Interfacig_with_Rasbery_Pi_CERTIFICATE_PNG,
  Interfacing_with_Arduino_CERTIFICATE_PNG,
  Introduction_to_CSS_CERTIFICATE_PNG,
  Introduction_to_HTML5_CERTIFICATE_PNG,
  Introduction_to_IOT_and_Embedded_System_CERTIFICATE_PNG,
  Postman_Guided_Project_CERTIFICATE_PNG,
  Programming_for_EveryPDFbody_Certificate_PNG,
  Python_Data_Structure_Certificate_PNG,
  Python_Specialization_Certificate_PNG,
  The_Arduino_Platform_and_C_Programming_CERTIFICATE_PNG,
  The_Rasberry_Pi_and_Python_Programming_for_Rasberry_Pi_CERTIFICATE_PNG,
  Using_Database_with_Python_Certificate_PNG,
  Using_Python_to_access_Webdata_Certificate_PNG,
  Web_Design_Specialization_Certificate_PNG,
} from "./certificateImageConstant";
import {
  Web_Design_Specialization_Certificate,
  Introduction_to_HTML5_CERTIFICATE,
  Capstone_CERTIFICATE,
  Advance_Styling_with_Responsive_Design_CERTIFICATE,
  Interactivity_with_javaScript_CERTIFICATE,
  Introduction_to_CSS_CERTIFICATE,
  Python_Specialization_CERTIFICATE,
  Capstone_Python_CERTIFICATE,
  Using_Database_with_Python_CERTIFICATE,
  Using_Python_to_access_Webdata_CERTIFICATE,
  Python_Data_Structure_CERTIFICATE,
  Programming_for_Everybody_CERTIFICATE,
  Postman_Guided_Project_CERTIFICATE,
  Interfacig_with_Rasbery_Pi_CERTIFICATE,
  The_Rasberry_Pi_and_Python_Programming_for_Rasberry_Pi_CERTIFICATE,
  Interfacing_with_Arduino_CERTIFICATE,
  The_Arduino_Platform_and_C_Programming_CERTIFICATE,
  Introduction_to_IOT_and_Embedded_System_CERTIFICATE,
} from "./certificatePDFConstant";

const CERTIFICATES = [
  {
    id: "1",
    name: "Web Design Specialization",
    VerficationLink:
      "https://www.coursera.org/verify/specialization/RDFQFZ95N59T",
    image: Web_Design_Specialization_Certificate_PNG,
    pdf: Web_Design_Specialization_Certificate,
    category: "specialization",
    platform: "coursera",
  },
  {
    id: "2",
    name: "Introduction to HTML5",
    VerficationLink: "https://www.coursera.org/verify/L9MD5W7PYEXZ",
    image: Introduction_to_HTML5_CERTIFICATE_PNG,
    pdf: Introduction_to_HTML5_CERTIFICATE,
    category: "specialization",
    platform: "coursera",
  },
  {
    id: "3",
    name: "Introduction to CSS",
    VerficationLink: "https://www.coursera.org/verify/64F3ZZ8VXEPK",
    image: Introduction_to_CSS_CERTIFICATE_PNG,
    pdf: Introduction_to_CSS_CERTIFICATE,
    category: "specialization",
    platform: "coursera",
  },
  {
    id: "4",
    name: "Interactivity with javaScript",
    VerficationLink: "https://www.coursera.org/verify/DTYDXGE5DEE6",
    image: Interactivity_with_javaScript_CERTIFICATE_PNG,
    pdf: Interactivity_with_javaScript_CERTIFICATE,
    category: "specialization",
    platform: "coursera",
  },
  {
    id: "5",
    name: "Advance Styling with Responsive Design",
    VerficationLink: "https://www.coursera.org/verify/XEVGL8TRVZ9B",
    image: Advance_Styling_with_Responsive_Design_CERTIFICATE_PNG,
    pdf: Advance_Styling_with_Responsive_Design_CERTIFICATE,
    category: "specialization",
    platform: "coursera",
  },
  {
    id: "6",
    name: "Capstone",
    VerficationLink: "https://www.coursera.org/verify/TL4LWQSM92A2",
    image: Capstone_CERTIFICATE_PNG,
    pdf: Capstone_CERTIFICATE,
    category: "specialization",
    platform: "coursera",
  },
  {
    id: "7",
    name: "Programming for Everybody",
    VerficationLink: "https://www.coursera.org/verify/Y5KXUMGXT7LP",
    image: Programming_for_EveryPDFbody_Certificate_PNG,
    pdf: Programming_for_Everybody_CERTIFICATE,
    category: "specialization",
    platform: "coursera",
  },
  {
    id: "8",
    name: "Python Data Structure",
    VerficationLink: "https://www.coursera.org/verify/4G7V2CDXSJA3",
    image: Python_Data_Structure_Certificate_PNG,
    pdf: Python_Data_Structure_CERTIFICATE,
    category: "specialization",
    platform: "coursera",
  },
  {
    id: "9",
    name: "Using Python to access Webdata",
    VerficationLink: "https://www.coursera.org/verify/KEEED8T6KGPK",
    image: Using_Python_to_access_Webdata_Certificate_PNG,
    pdf: Using_Python_to_access_Webdata_CERTIFICATE,
    category: "specialization",
    platform: "coursera",
  },
  {
    id: "10",
    name: "Using Database with Python",
    VerficationLink: "https://www.coursera.org/verify/DL78NBH9CYG7",
    image: Using_Database_with_Python_Certificate_PNG,
    pdf: Using_Database_with_Python_CERTIFICATE,
    category: "specialization",
    platform: "coursera",
  },
  {
    id: "11",
    name: "Capstone",
    VerficationLink: "https://www.coursera.org/verify/RYXZLZWQST5D",
    image: Capstone_Python_Certificate_PNG,
    pdf: Capstone_Python_CERTIFICATE,
    category: "specialization",
    platform: "coursera",
  },
  {
    id: "12",
    name: "Python Specialization",
    VerficationLink:
      "https://www.coursera.org/verify/specialization/BLXKR845DAJM",
    image: Python_Specialization_Certificate_PNG,
    pdf: Python_Specialization_CERTIFICATE,
    category: "specialization",
    platform: "coursera",
  },
  {
    id: "13",
    name: "Postman Guided Project",
    VerficationLink: "https://www.coursera.org/verify/DCV9JJZ5UGFV",
    image: Postman_Guided_Project_CERTIFICATE_PNG,
    pdf: Postman_Guided_Project_CERTIFICATE,
    category: "specialization",
    platform: "coursera",
  },
  {
    id: "14",
    name: "Introduction to IOT and Embedded System",
    VerficationLink: "https://www.coursera.org/verify/MCAANTFGH5QD",
    image: Introduction_to_IOT_and_Embedded_System_CERTIFICATE_PNG,
    pdf: Introduction_to_IOT_and_Embedded_System_CERTIFICATE,
    category: "specialization",
    platform: "coursera",
  },
  {
    id: "15",
    name: "The Arduino Platform and C Programming",
    VerficationLink: "https://www.coursera.org/verify/QDPZ2N3KDT54",
    image: The_Arduino_Platform_and_C_Programming_CERTIFICATE_PNG,
    pdf: The_Arduino_Platform_and_C_Programming_CERTIFICATE,
    category: "specialization",
    platform: "coursera",
  },
  {
    id: "16",
    name: "Interfacing with Arduino",
    VerficationLink: "https://www.coursera.org/verify/AVUJGJGXSP68",
    image: Interfacing_with_Arduino_CERTIFICATE_PNG,
    pdf: Interfacing_with_Arduino_CERTIFICATE,
    category: "specialization",
    platform: "coursera",
  },
  {
    id: "17",
    name: "The Rasberry Pi and Python Programming for Rasberry Pi",
    VerficationLink: "https://www.coursera.org/verify/G4MUKUJWXKS4",
    image:
      The_Rasberry_Pi_and_Python_Programming_for_Rasberry_Pi_CERTIFICATE_PNG,
    pdf: The_Rasberry_Pi_and_Python_Programming_for_Rasberry_Pi_CERTIFICATE,
    category: "specialization",
    platform: "coursera",
  },
  {
    id: "18",
    name: "Interfacig with Rasbery Pi",
    VerficationLink: "https://www.coursera.org/verify/J5423J4DFE2J",
    image: Interfacig_with_Rasbery_Pi_CERTIFICATE_PNG,
    pdf: Interfacig_with_Rasbery_Pi_CERTIFICATE,
    category: "specialization",
    platform: "coursera",
  },
];

export default CERTIFICATES;
